:root {
  --secondary-color: #0d76c4;
  --dark-navy-color: #233260;
  --light-navy-color: #d0dcff;
  --bs-warning: #e7c500;
  --dark-primary-color: #610000;
  /* --bs-primary-light: #fefff1; */
  --bs-danger: #e62d2d;
}

html,
body {
  font-family: 'Roboto', 'Helvetica', 'sans-serif';
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 0.4rem;
}

.sidebar-appname {
  align-items: center;
  padding: 1rem 0.5rem;
}

.menu-item .menu-link {
  padding: 1rem;
}

.sidebar-appname img {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
}

.sidebar-appname__container {
  padding-left: 12px;
}

.sidebar-appname__title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 1;
}

.sidebar-appname__subtitle {
  font-size: 0.85rem;
  font-weight: 500;
  color: #fff;
  line-height: 1;
}

.sidebar-userinfo__container {
  transition: color 0.2s ease;
  background-color: #8f0000;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.sidebar-userinfo__container-top {
  display: flex;
}

.sidebar-userinfo__icon > span {
  width: 52px;
  height: 52px;
  background-color: #f2f0f0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdfe9;
}

.sidebar-userinfo__info {
  padding-left: 0.8rem;
}

.sidebar-userinfo__role {
  font-weight: 700;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.sidebar-userinfo__major {
  background-color: var(--bs-warning);
  color: #7b6a00;
  border-radius: 32px;
  padding: 0.5rem 1.5rem;
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active .menu-title,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active .menu-icon,
[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-icon
  .svg-icon,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active .menu-icon i {
  color: #fff;
}

[data-kt-app-sidebar-minimize='on'] .app-sidebar .sidebar-userinfo__action,
[data-kt-app-sidebar-minimize='on'] .app-sidebar .sidebar-userinfo__blockinfo {
  display: none;
}

[data-kt-app-sidebar-minimize='on'] .app-sidebar .sidebar-userinfo__icon > span {
  height: 34px;
  width: 34px;
}

[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active {
  background-color: #f2182e;
}

.react-select__control {
  /* border: 0 !important; */
  /* background-color: #f9f9f9 !important; */
  padding: 2.5px 8px;
  border-color: var(--bs-gray-300) !important;
  border-radius: 0.475rem !important;
}

.flatpickr-year-select {
  margin-top: 8px;
}

.flatpickr-year-select select {
  border: 1px solid #ccc;
  padding: 2px 4px;
  border-radius: 4px;
  color: #868686;
  font-weight: 600;
}

.inline-calendar .flatpickr-calendar {
  border: 0;
  box-shadow: none;
  width: 100% !important;
}

/* .inline-calendar .flatpickr-months {
    display: none;
} */

.flatpickr-calendar {
  width: 320px !important;
}

.flatpickr-current-month {
  padding: 0;
}

.schedule-info-list {
  list-style-type: none;
  padding-left: 0px;
}

.schedule-info-list li {
  padding: 2px;
  font-size: 1.1rem;
}

.indicator-calendar {
  font-size: 8px;
  position: absolute;
  right: 5px;
  display: flex;
  list-style-type: none;
  padding: 0;
  font-weight: 700;
  line-height: 1;
  bottom: 0;
  top: 0;
  margin: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.schedule-indicator {
  position: absolute;
  left: 0px;
  top: 13px;
}

.schedule-type-pill {
  width: 24px;
  height: 24px;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  font-size: 0.85rem;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: 700;
}

.schedule-type-pill.pill-Meeting,
.badge-Meeting {
  border-color: #3e97ff;
  background-color: #3e97ff;
}

.schedule-type-pill.pill-Assignment,
.badge-Assignment {
  border-color: #eb7d00;
  background-color: #eb7d00;
}

.schedule-type-pill.pill-Quiz,
.badge-Quiz {
  border-color: #07a744;
  background-color: #07a744;
}

.schedule-type-pill.pill-CampusEvent,
.badge-CampusEvent {
  border-color: #9a00c5;
  background-color: #9a00c5;
}

.schedule-type-pill.pill-PublicHoliday,
.badge-PublicHoliday {
  border-color: #df0000;
  background-color: #df0000;
}

.schedule-type-pill.pill-Attachment {
  border-color: #0066ff;
  background-color: #0066ff;
  cursor: pointer;
}

.schedule-type-pill.pill-Attachment > i {
  /* color: #0066ff; */
}

.calendar-icon-text {
  position: absolute;
  top: 34px;
  width: 100%;
  text-align: center;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: 500;
}

.card-upcoming {
  display: flex;
  /* flex-wrap: wrap; */
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar {
  background-color: #700000;
  border-right: 0;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu > .menu-item .menu-link.active {
  transition: color 0.2s ease;
  background-color: #960000;
  color: var(--bs-primary-inverse);
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu > .menu-item .menu-link .menu-icon i {
  color: #fff;
}

.btn.btn-light-warning {
  color: #af9600;
}

.page-link.active,
.active > .page-link {
  background-color: var(--bs-warning);
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-color: var(--bs-danger);
}

.menu-state-bg .menu-item .menu-link.active .menu-title {
  color: #7b6a00;
}

.dropzone {
  border: 1px dashed var(--bs-warning);
  background-color: var(--bs-warning-light);
}

.class-card:hover {
  background-color: #f9f9f9;
}

.sidebar-userinfo__action {
  display: flex;
  gap: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 12px;
  margin-top: 12px;
}

.sidebar-userinfo__action-item {
  flex: 1;
}

.sidebar-userinfo__action-item i {
  display: block;
  font-size: 1.4rem;
  padding: 0;
  margin-bottom: 2px;
}

.sidebar-userinfo__action-item .btn {
  padding: 10px 8px !important;
}

.app-header {
  display: none;
}

[data-kt-app-header-fixed='true'] .app-wrapper {
  margin-top: 0;
}

.fs-24rem {
  font-size: 2.4rem !important;
}

.announcement-carousel-item {
  min-height: 230px;
}

.announcement-class-carousel-item {
  min-height: 205px;
}

.event-Meeting {
  background-color: #dfecff !important;
  border-color: rgb(210, 222, 255) !important;
  color: #0066ff !important;
}

.event-Meeting.deleted .fc-event-title {
  text-decoration: line-through !important;
}

.fc-h-event.event-Meeting .fc-event-main {
  color: #0066ff !important;
  font-weight: 500;
}

.event-Assignment {
  background-color: #fff0df;
  border-color: #ffdcb5;
}

.fc-h-event.event-Assignment .fc-event-main {
  color: #eb7d00 !important;
  font-weight: 500;
}

.event-Quiz {
  background-color: #d3ffe6 !important;
  border-color: #bcffd9 !important;
  color: #009641 !important;
  font-weight: 500 !important;
}

.fc-h-event.event-Quiz .fc-event-main {
  color: #009641 !important;
  font-weight: 500 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f5f5f5;
}

.event-CampusEvent {
  background-color: #f8dfff;
  border-color: #f8dfff;
}

.fc-h-event.event-CampusEvent .fc-event-main {
  color: #9a00c5 !important;
  font-weight: 500;
}

.event-PublicHoliday {
  background-color: #ffdfdf;
  border-color: #ff9d9d;
}

.fc-h-event.event-PublicHoliday .fc-event-main {
  color: #df0000 !important;
  font-weight: 500;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(80 80 80 / 8%);
}

.fc-theme-standard td.has-events {
  cursor: pointer;
  opacity: 0.8;
}

.fc .fc-highlight {
  background-color: rgb(80 80 80 / 8%);
}

#kt_app_sidebar_menu_wrapper {
  height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: hidden;
}

#kt_app_sidebar_menu_wrapper {
  scrollbar-color: #c30f0f transparent;
}

.quill .ql-editor {
  height: 250px;
}

.quill.quill-md .ql-editor {
  height: 140px;
}

.quill.quill-sm .ql-editor {
  height: 80px;
}

.is-invalid .ql-toolbar {
  border-color: var(--bs-danger) !important;
}

.is-invalid .ql-container {
  border-color: var(--bs-danger) !important;
}

/* Chart css */

.semi-donut {
  --percentage: 0;
  --fill: rgb(18, 153, 13);
  width: 140px;
  height: 70px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}

.semi-donut:after {
  content: '';
  width: 140px;
  height: 140px;
  border: 20px solid;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill) var(--fill);
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
  /* -webkit-animation: fillAnimation 1s ease-in; */
  /* animation: fillAnimation 1s ease-in; */
}

@-webkit-keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(135deg);
  }
}

@keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(135deg);
  }
}

@-webkit-keyframes fillGraphAnimation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }
}

@keyframes fillGraphAnimation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }
}

/* End Chart */

.btn-xs {
  padding: 8px !important;
  font-size: 0.8rem !important;
}

.grid-picture {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  object-fit: cover;
}

.html-renderer {
  line-height: 1.75;
  font-size: 14px;
}

.html-renderer.question p {
  margin-bottom: 0 !important;
}

input.flatpickr-mobile {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-300);
  appearance: none;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
  border-color: var(--bs-gray-300);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

/*DASHBOARD WRAPPER*/

.upcoming-wrapper {
  height: 230px;
  overflow-y: auto;
}

.todo-wrapper {
  height: calc(50vh - 36px);
  overflow-x: auto;
}

.active-student-wrapper {
  height: 230px;
  overflow-y: auto;
}

.today-meets-wrapper {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.session-menu,
.quiz-menu {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.classes-students-card {
  height: 420px;
  overflow: auto;
}

.table-meet {
  height: calc(100vh - 380px);

  table thead tr th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 100;
  }
}

.pagination-row {
  min-height: 65px;
}

table.header-fix thead tr th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.sticky-top {
  z-index: 9;
}

.sticky-start {
  position: sticky;
  z-index: 10;
  left: 0;
}

.sticky-end {
  position: sticky;
  z-index: 10;
  right: 0;
}

.table-sticky > :not(caption) > * > * {
  --bs-table-color-type: white;
  --bs-table-bg-type: white;
}

.table-sticky > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.fs-6rem {
  font-size: 6rem !important;
}

.tab-nav-header > ul {
  padding-bottom: 2px;
}

.scroller-navbar {
  margin-bottom: -20px !important;
}

.fab-announcement {
  position: absolute;
  right: 22px;
  bottom: 12px;
  z-index: 99;
  border: 1px solid #fff !important;
}

.fab-announcement i {
  padding: 0;
}

.scanner-modal-body {
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-top: 56.25%; */
}

.video-container video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.scan-result-body {
  width: 90%;
  background-color: #f9f9f9;
  padding: 24px;
  border-radius: 12px;
  min-height: 450px;
  border: 1px solid #e7e7e7;
}

.card-hoverable {
  cursor: pointer;
}

.card-hoverable:hover {
  background-color: #fbfbfb !important;
}

.modal-notif-survey .modal-dialog {
  margin-top: 64px;
}

.modal-center {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.survey .question-parent {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.survey .question-child {
  min-height: 0 !important;
  height: auto !important;
  font-size: 15px;
  font-weight: normal;
  text-transform: none;
  border-bottom: 1px solid #f1f1f1;
}

.survey .alert-error {
  color: var(--bs-danger);
  font-size: 14px;
  font-weight: 400;
  height: 21px;
}

.survey .option-group {
  float: none;
}

.survey .option-group label {
  display: inline;
  float: none;
  width: 100px;
}

.survey .option-group input {
  float: none;
  margin: 0px;
  width: 20px;
  position: relative;
}

.survey .option-group .radio-item {
  padding-top: 18px;
  display: -webkit-inline-box;
  display: inline-flex;
  width: 16.667%;
  -webkit-box-pack: center;
  justify-content: center;
}

.survey .option-group .radio-item label {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0.75;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}

.survey .option-group .radio-item label img {
  width: 40px;
}

.survey input[type='radio'] + img {
  cursor: pointer;
}

.survey .star-rating {
  white-space: nowrap;
  padding-top: 18px;
}

.survey .star-rating img {
  width: 40px;
}

.survey .star-rating [type='radio'] {
  appearance: none;
}

.survey .star-rating img {
  font-size: 1.2em;
  transition: 0.3s;
  filter: grayscale(1);
}

.survey .star-rating label:is(:hover, :has(~ :hover)) img,
.survey .star-rating label:is(:checked, :has(~ :checked)) img,
.survey .star-rating label:is(:focus, :has(~ :focus)) img {
  transform: scale(1.15);
  animation: jump 0.5s calc(0.3s + (var(--i) - 1) * 0.15s) alternate infinite;
  filter: grayscale(0.2);
  cursor: pointer;
}

.survey .star-rating label:has(~ :checked) img {
  filter: grayscale(0);
}

.tab-nav-header > ul {
  white-space: nowrap;
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
}

.tab-nav-header > ul li {
  display: inline-block;
}

.announcement-modal {
  max-width: 850px;
}

th.rating-header img {
  width: 16px;
}

td.rating-column img {
  width: 16px;
}

.modal-80 {
  width: 80% !important;
  height: 95% !important;
  margin: 0 10%;
}

.fullscreen-mode .app-toolbar,
.fullscreen-mode .app-sidebar {
  display: none !important;
}

.fullscreen-mode .app-wrapper {
  margin-left: 0 !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: 0.4;
}

.obscure {
  -webkit-text-security: disc;
}

.group-header {
  background: var(--bs-table-striped-bg);
  border-top: 1px dashed !important;
}

.table-row-even {
  background: white;
}

.table-row-odd {
  background: var(--bs-table-striped-bg);
}

.modal-backdrop {
  --bs-backdrop-zindex: 1055;
}

div {
  --webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-caret.dropdown-toggle::after {
  display: none;
}

.card-class-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  min-height: 45px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
}

/* p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;    ` 
} */

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

select.form-control {
  appearance: auto;
}

.menu-link.disabled {
  background-color: lightgray;
}

.form-question .ck-content {
  min-height: 100px;
}

.btn.btn-readonly {
  cursor: default !important;
}

.description-modal .modal-dialog {
  max-width: 400px;
}

.strikethrough {
  text-decoration: line-through;
}

.pending-survey-total-card {
  margin-top: 40px;
}

.table-question .html-renderer table {
  width: 100%;
}
.table-question .html-renderer table tr td {
  border: 1px solid #000 !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.table-question .html-renderer table tr:last-child td {
  border-bottom: 1px solid #000 !important;
}

.quiz-navigation-grid {
  min-height: 300px;
}

@media screen and (max-width: 1400px) {
  .card-upcoming .action-container {
    flex: 0 0 auto;
    width: 100%;
    justify-content: end;
    margin-bottom: 1rem;
  }

  .announcement-carousel-item {
    min-height: 180px;
  }

  .active-student-wrapper {
    height: 183px;
  }

  .upcoming-wrapper {
    height: 183px;
  }

  .todo-wrapper {
    height: calc(100vh - 426px);
    overflow-y: auto;
  }

  .today-meets-wrapper {
    height: calc(100vh - 426px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 1199px) {
  .active-student-wrapper {
    height: auto;
    overflow: hidden;
  }

  .todo-wrapper {
    height: auto;
    overflow-y: hidden;
  }

  .upcoming-wrapper {
    height: auto;
    overflow: hidden;
  }

  .modal-80 {
    width: 100% !important;
    height: 100% !important;
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .today-meets-wrapper {
    height: auto;
    overflow-y: hidden;
  }

  .todo-wrapper {
    height: auto;
    overflow: hidden;
  }

  .app-header {
    display: flex;
  }

  .announcement-modal {
    max-width: 95%;
  }
}

@media screen and (max-width: 768px) {
  [data-kt-app-toolbar-enabled='true']:not([data-kt-app-toolbar-fixed-mobile='true']) .app-content {
    padding-top: 20px;
  }

  #kt_app_header_container {
    width: 100%;
  }

  .announcement-carousel-item {
    min-height: 120px;
    max-height: 200px;
  }

  .announcement-class-carousel-item {
    min-height: 210px;
  }

  .menu-scroll-xs-150px {
    overflow: auto;
    height: 150px;
  }

  .fc .fc-toolbar-chunk {
    text-align: center;
    width: 100%;
  }

  .session-menu,
  .quiz-menu {
    max-height: 200px;
  }

  .scanner-modal-body {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-center {
    min-height: auto;
  }

  .pending-survey-total-card {
    margin-top: 0;
  }
  .quiz-navigation-grid {
    min-height: 0px;
  }
}

@media screen and (max-width: 480px) {
  .upcoming-wrapper {
    height: auto;
    overflow: hidden;
  }

  .todo-wrapper {
    height: auto;
    overflow: hidden;
  }

  .active-student-wrapper {
    height: auto;
    overflow-y: hidden;
  }

  .todo-wrapper {
    height: auto;
    overflow-y: hidden;
  }

  .dashboard-filter-container {
    flex-direction: column;
  }

  .dashboard-filter-container > * {
    margin-bottom: 18px;
  }
}

@keyframes jump {
  0%,
  50% {
    transform: translatey(0) scale(1.15);
  }

  100% {
    transform: translatey(-15%) scale(1.15);
  }
}

.has-error .ck.ck-editor__main > .ck-editor__editable {
  border-color: var(--bs-danger) !important;
}

.has-error .word-info {
  color: var(--bs-danger);
}
